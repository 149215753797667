import { createStore } from 'vuex'

export default createStore({
  state: {
    cat_id:0,
    userId:'',
    firstName:'Guest User',
    lastName:'',
    avatar:'https://apps.programage.com/InshutiManager/view/socialV/assets/images/user/04.jpg'
  },
  getters: {
  },
  mutations: {
    addUserInfo(state,payload){
      let user = payload;
      state.userId=user.user_id
      state.firstName=user.first_name
      state.lastName=user.last_name
      state.avatar='https://tshaku.com/'+user.avatar
    },
    updateAvatar(state,payload){
      state.avatar='https://tshaku.com/'+payload
    },
    updatefName(state,payload){
      state.firstName=payload
    },
    updatelName(state,payload){
      state.lastName=payload
    },
    updateCatId(state,payload){
      state.cat_id=payload
    }
  },
  actions: {
    addUserInfo: (context,payload) =>{
      context.commit("addUserInfo",payload)
    },
    updateAvatar: (context,payload) =>{
      context.commit("updateAvatar",payload)
    },
    updatefName: (context,payload) => {
      context.commit("updatefName",payload)
    },
    updatelName: (context,payload) => {
      context.commit("updatelName",payload)
    },
    updateCatId: (context,payload) => {
      context.commit("updateCatId",payload)
    }
  },
  modules: {
  }
})

<template>   
    <!-- loader END -->        
        <section class="sign-in-page">
            <div id="container-inside">
                <div id="circle-small"></div>
                <div id="circle-medium"></div>
                <div id="circle-large"></div>
                <div id="circle-xlarge"></div>
                <div id="circle-xxlarge"></div>
            </div>
            <div class="container p-0">
                <div class="row no-gutters">
                    <div class="col-md-6 text-center pt-5">
                        <div class="sign-in-detail text-white">
                            <a class="sign-in-logo mb-5" href="#"><h2>TshaKu</h2></a>
                            <div class="sign-slider overflow-hidden ">
                                <ul  class="swiper-wrapper list-inline m-0 p-0 ">
                                    <li class="swiper-slide">
                                        <img src="https://tshaku.com/view/socialV/assets/images/login/1.png" class="img-fluid mb-4" alt="logo">
                                        <h4 class="mb-1 text-white">Find new friends</h4>
                                        <p>All over the world</p>
                                    </li>
                                    <li class="swiper-slide">
                                        <img src="https://tshaku.com/view/socialV/assets/images/login/2.png" class="img-fluid mb-4" alt="logo"> 
                                        <h4 class="mb-1 text-white">Connect with the world</h4>
                                        <p>All on one platform</p>
                                    </li>
                                    <li class="swiper-slide">
                                        <img src="https://tshaku.com/view/socialV/assets/images/login/3.png" class="img-fluid mb-4" alt="logo">
                                        <h4 class="mb-1 text-white">Create new events</h4>
                                        <p>Birthdays, weddings, parties</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 bg-white pt-5 pt-5 pb-lg-0 pb-5">
                        <div class="sign-in-from">
                            <h1 class="mb-0">Sign in</h1>
                            <p>Enter your email address and password to access admin panel.</p>
                            <form class="mt-4" @submit.prevent="handleLogin">
                                <div class="form-group">
                                    <label class="form-label" for="exampleInputEmail1">Email address</label>
                                    <input type="email" class="form-control mb-0" id="exampleInputEmail1" placeholder="Enter email" v-model="email">
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="exampleInputPassword1">Password</label>
                                    <a href="#" class="float-end">Forgot password?</a>
                                    <input type="password" class="form-control mb-0" id="exampleInputPassword1" placeholder="Password" name="password" v-model="password">
                                </div>
                                <div class="d-inline-block w-100">
                                    <div class="form-check d-inline-block mt-2 pt-1">
                                        <input type="checkbox" name="remember_user" checked="checked" class="form-check-input" id="customCheck11">
                                        <label class="form-check-label" for="customCheck11">Remember Me</label>
                                    </div>
                                    <button type="submit" class="btn btn-primary float-end">Sign in</button>
                                </div>
                                <div class="sign-info">
                                    <span class="dark-color d-inline-block line-height-2">Don't have an account? <router-link to="/register">Sign up</router-link></span>
                                    <ul class="iq-social-media">
                                        <li><a href="#"><i class="ri-facebook-box-line"></i></a></li>
                                        <li><a href="#"><i class="ri-twitter-line"></i></a></li>
                                        <li><a href="#"><i class="ri-instagram-line"></i></a></li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
</template>

<script>
import router from '@/router'
import axios from 'axios'

export default {
    name : 'Login',
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        async handleLogin(){
            if(!this.email || !this.password){
                alert('Please fill in the email and password')
            }
            else{
                var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if(!this.email.match(validRegex)){
                    alert('Please input a valid email address')
                }
                else{
                    var form = new FormData();        
                    form.append('action','userlogin')
                    form.append('type','inshuti')
                    form.append('email',this.email)
                    form.append('password',this.password)
                    const response = await axios.post('api.php',form)
                    if(response.status==200){
                        //Communication succesfull
                        var data = response.data;
                        if(data.response_code==200){                            
                            //Login was succesfull
                            localStorage.setItem('token',data.token)
                            //Redirect to homepage
                            //Set the store variables
                            this.$store.replaceState({})
                            this.$store.dispatch("addUserInfo",data)
                            this.$router.push('/');
                        }
                        else{
                            alert('Wrong email and password combination')
                            this.$router.go('/');
                        }
                    }
                    else{
                        alert('An error occurd during communication with the server')
                    }
                    //console.log(response);
                }
            }            
        }
    },
    
}
</script>    
<style scopped>
    #wrapper{
        background:blue;
    }
</style>
<template>  
 <ContentHeader />
  <div id="content-page" class="content-page">
  <div class="container">
    <div class="row">   
        <SocialPoster :avatar="avatar"/>
        <input type="hidden" name="page_id" id="page_id" value="0"/>

    </div>
    <div class="row">
        <div class="col-lg-12">            
        </div>
    </div>
    <div class="row">           
        <div class="col-lg-12 row m-0 p-0">
            <div class="col-sm-12">         
                <!-- START USER POSTS -->                     
                <div id="all-wall-posts">                        
                    <!-- END USER POSTS -->     
                    <div v-for="post in posts" :key="post.post_id">
                        <Posts :seen_posts="seen_posts" :post_comments="post.post_comments" :post_date="post.post_date" :post_user_sub="post.user_sub" :post_text="post.post_text" :post_shares="post.post_shares" :post_link="post.post_link" :post_likes="post.post_likes" :post_page_id="post.post_page_id" :post_image="post.post_image" :post_by_avatar="post.post_by_avatar" :post_by_id="post.post_by_id" :post_by_names="post.post_by_names" :post_id="post.post_id"  :post_type="post.post_type" /> 
                    </div>       
                </div>  
                <input type="hidden" id="active_post" value="0"/>
                <div id="spin_loader"><img src="https://tshaku.com/view/socialV/assets/images/page-img/page-load-loader.gif"/></div>         
                    <router-link :to="'/'" class="btn btn-primary d-block w-100 mt-3">Load More Posts</router-link>
                    <input type="hidden" id="last_loaded_post" value="0"/>
                    <br/>                               
                </div> 
            </div>
            <div class="col-sm-12 text-center">                            
            </div>
        </div>
    </div>
  </div>
  <ContentFooter/>
</template>

<script>
import router from '@/router'
import axios from 'axios'
import ContentHeader from './includes/ContentHeader.vue'
import ContentFooter from './includes/ContentFooter.vue'
import SocialPoster from './includes/SocialPoster.vue'
import Posts from './includes/Post.vue'
export default {
    name: 'Home',
    data(){
        return{
            posts:'',                        
            seen_posts:'',
            posts_loaded:false           
        }        
    },
    computed:{
        
    },
    props:['cat_id'],
    watch: { 
        cat_id: function() { // watch it
            this.load_posts()
            this.scrollToTop()
        }
    },
    mounted(){ 
        
    },
    components:{SocialPoster,Posts,ContentHeader,ContentFooter},    
    created(){ 
        this.load_posts()
        //*/
    },     
    methods:{  
        scrollToTop(){
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },              
        check_posts(){        
            $(window).scroll(function() {             
            const posts=JSON.parse(localStorage.getItem('Home_posts'))
            if(posts){                                           
                for (var k in posts){                    
                    var post=posts[k]                                        
                    var the_div='post_is_'+post.post_id                                                            
                    var element = document.getElementById(the_div)
                    if(element){
                        const elementTop = element.offsetTop;             
                        const elementBottom = elementTop + element.offsetHeight; 
                        const viewportTop = window.pageYOffset; 
                        const viewportBottom = viewportTop + window.innerHeight;   
                        if (elementBottom > viewportTop && elementTop < viewportBottom) {
                            //Check if the seen post is already in the array
                            if(!this.seen_posts){
                                //The seen posts variable is empty
                                this.seen_posts+='|'+post.post_id
                            }
                            else{
                                //The seen posts variable is not empty
                                //Check if this post was already added to it
                                var seen_posts=this.seen_posts;
                                var myarr = seen_posts.split('|');
                                if(!myarr.includes(post.post_id)){
                                    //This is not yet recorded
                                    if(post.post_id!='undefined'){
                                        this.seen_posts+='|'+post.post_id                                           
                                        localStorage.setItem('Seen_posts', this.seen_posts);                                        
                                    }                                    
                                }
                            }
                            //console.log(this.seen_posts)
                            
                        } 
                        else {
                            //else what?;
                        }                                   
                    }                    
                }
                //*/
                //Check if any of the loaded posts is in the view port right now
            } 
            //*/           
            });        
        },
        async load_posts(){            
            //*
            //const cachedData = JSON.parse(localStorage.getItem('Home_posts'));
            this.posts='';
            this.$store.dispatch("updateCatId",this.cat_id)
            if(0){
                this.posts=cachedData                
            }
            else{
                const posts = await axios.get('post.php?action=load&cat_id='+this.cat_id,{
                                headers: {
                                    Authorization:localStorage.getItem('token')
                                }
                            })                   
                //localStorage.setItem('Home_posts', JSON.stringify(posts.data));
                this.posts=posts.data    
                this.posts_loaded=true    
                //Watch for seen posts              
                setTimeout(this.check_posts(),50)
                //End Watch for seen posts
                //console.log(posts.data)
                
            }            
        },
        visibilityChanged () {     
            //alert('Post id Is visible')
        }
    }   
}
</script>

<style>
    
</style>